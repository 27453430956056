import { AnyObject } from '@/core/general'

import { MarkerPosition } from './leaflet.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export class Marker <D extends AnyObject = AnyObject> {
  public id: string
  protected className?: string
  protected element?: string
  protected latitude: MarkerPosition['latitude']
  protected longitude: MarkerPosition['longitude']
  protected data: D
  public onClick: CallableFunction

  constructor (latitude: number, longitude: number, element: string, data: D, onClick: CallableFunction, className?: string) {
    this.id = `marker-${(Math.random() + 1).toString(36).substring(7)}`
    this.className = className ?? 'marker'
    this.element = element
    this.latitude = latitude
    this.longitude = longitude
    this.data = data
    this.onClick = onClick
  }

  public toArray (): any[] {
    return [this.id, this.latitude, this.longitude, this.element, this.className]
  }

  public getData (): D {
    return this.data
  }
}