
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

import { ILeafletService, LeafletServiceType } from '@/shared/services/leaflet'
import { Marker } from '@/shared/services/leaflet/marker'
import { IRootService, RootServiceType } from '@/modules/root/services/root'
import { AnyObject } from '@/core/general'
import { mapColorsRegistry, mapIconsRegistry } from '@/modules/events/config'
import { EventModel } from '@/modules/events/models'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Map>({
  name: 'Map',
  mounted (): void {
    this.initMap()
    this.populateWithMarkers()
    this.flyToSelected()
  }
})
export class Map extends Vue {
  @Prop({ type: Array, required: true })
  protected readonly objects!: Marker[]

  @Prop({ type: Object, required: false })
  protected readonly selected?: Marker

  protected readonly rootService: IRootService =
    this.$container.get(RootServiceType)

  /**
   * Instance of the leaflet service.
   */
  protected readonly leafletService: ILeafletService =
    this.$container.get(LeafletServiceType)

  protected populateWithMarkers (): void {
    for (const object of this.objects) {
      this.leafletService.setMarker(object)
    }
  }

  protected initMap (): void {
    const { latitude, longitude } = this.rootService.getProperty<AnyObject>('location')
    this.leafletService.init('map', { latitude, longitude  })
  }

  protected flyToSelected (): void {
    if (this.selected) {
      const [_, latitude, longitude] = this.selected.toArray()

      this.leafletService.flyToLocation({
        latitude,
        longitude
      })
    }
  }

  protected replaceMarkerWhenSelected (): void {
    const [id, latitude, longitude, element, className ] = this.selected!.toArray()
    this.leafletService.removeLayer(id)
    if (Array.isArray(this.selected?.getData()) && this.selected?.getData().length > 0) {
      this.leafletService.addLayer(
        new Marker(
          latitude, longitude,
          `
        <div
          style="
            background: white;
            color: #fff;
            font-weight: 600;
            box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.10);"></div>\`
        `,
          className,
          this.selected!.onClick
        )
      )
    } else {
      this.leafletService.addLayer(
        new Marker(
          latitude, longitude,
          `
        <div
          class="${this.selected?.getData().category().key}"
          style="
          background-color: ${mapColorsRegistry[this.selected?.getData().category().key].color};
          box-shadow: ${mapColorsRegistry[this.selected?.getData().category().key].border};
          border: 2px solid #fff; }"></div>\`
        `,
          className,
          this.selected!.onClick
        )
      )
    }
  }

  protected replaceMarkerWhenUnSelected (unselectedMarker: Marker | null): void {
    if (!unselectedMarker) {
      return
    }

    const [id, latitude, longitude, element, className ] = unselectedMarker.toArray()
    this.leafletService.removeLayer(id)

    const colors = [
      'linear-gradient(90deg, #8A2387 0%, #E94057 50%, #F27121 100%)',
      'linear-gradient(90deg, #FC00FF 0%, #00DBDE 100%)',
      'linear-gradient(90deg, #C33764 0%, #1D2671 100%)',
      'linear-gradient(90deg, #2196F3 0%, #F44336 100%);'
    ]

    if (Array.isArray(unselectedMarker.getData()) && unselectedMarker.getData().length > 0) {
      const randomIndex = Math.floor(Math.random() * colors.length)
      this.leafletService.addLayer(
        new Marker(
          latitude, longitude,
          `
        <div
            style="
             background: ${colors[randomIndex]};
             color: #fff;
             font-weight: 600;
             box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.10);">
                +${unselectedMarker.getData().length}
        </div>\`
        `,
          className,
          unselectedMarker.onClick
        )
      )
    } else {
      this.leafletService.addLayer(
        new Marker(
          latitude, longitude,
          `
        <div
            class="${unselectedMarker?.getData().category().key}"
            style="
            background-color: ${mapColorsRegistry[unselectedMarker.getData().category().key].color};
            box-shadow: ${mapColorsRegistry[unselectedMarker.getData().category().key].border};">
                ${mapIconsRegistry[unselectedMarker?.getData().category().key]}
        </div>\`
        `,
          className,
          unselectedMarker.onClick
        )
      )
    }
  }

  @Watch('selected')
  protected onSelectedChange
  (marker: Marker<EventModel> | null, prevMarker: Marker<EventModel> | null): void {
    if (marker) {
      this.flyToSelected()
      this.replaceMarkerWhenSelected()
    }

    this.replaceMarkerWhenUnSelected(prevMarker)
  }
}

export default Map
