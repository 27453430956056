
import { Component, Watch } from 'vue-property-decorator'

import { AnyObject } from '@/core/general'

import { IRootService, RootServiceType } from '../../../../root/services/root'

import { FilterAware } from '@/shared/components/molecules/Filter'
import { mapColorsRegistry, mapIconsRegistry } from '@/modules/events/config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<CategoryFilters>({
  name: 'CategoryFilters',
  mounted (): void {
    this.$emit('onSelectedCategories', this.fCategories.join(','))
  }
})
export class CategoryFilters extends FilterAware {
  protected readonly rootService: IRootService = this.$container.get(RootServiceType)

  protected appliedFiltersData: AnyObject = {}

  public readonly mapIconsRegistry = mapIconsRegistry
  public readonly mapColorsRegistry = mapColorsRegistry

  public get fCategories (): string[] {
    return this.queryParams?.categories ? this.queryParams?.categories.split(',') : []
  }

  public set fCategories (categories: string[]) {
    this.queryParams = {
      ...this.queryParams,
      categories: categories.join(',')
    }
  }

  @Watch('fCategories')
  protected onCategoriesUpdate (): void {
    this.$emit('onSelectedCategories', this.fCategories.join(','))
  }
}

export default CategoryFilters
