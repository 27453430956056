import { CarouselConfig } from '@/dsl/molecules/Carousel/Carousel.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const carouselConfig: CarouselConfig = {
  loop: false,
  slidesPerView: 1.1,
  autoHeight: true,
  spaceBetween: 10
}