
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'

import { AnyObject } from '@/core/general'

import { Marker } from '@/shared/services/leaflet/marker'
import { EmptyBanner } from '@/shared/components/molecules/EmptyBanner'
import { AmplitudeType, AppAmplitudeEvent, IAmplitude } from '@/shared/services/amplitude'

import { Map } from '../components/organisms/Map'
import { CategoryFilters } from '../components/molecules/CategoryFilters'
import { MapSelectedEvent } from '../components/molecules/MapSelectedEvent'
import { MapSelectedEvents } from '../components/molecules/MapSelectedEvents'
import { EventModel } from '../models'
import { mapColorsRegistry, mapIconsRegistry } from '../config'
import { EventsRepositoryType, IEventsRepository } from '../contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<MapView>({
  name: 'MapView',
  components: {
    Map,
    CategoryFilters,
    MapSelectedEvent,
    MapSelectedEvents,
    EmptyBanner
  },
  mounted (): void {
    if (this.hasEventInQuery) {
      this.initialSelectedEvent = this.$route.query.event.toString()
    }

    if (typeof window !== 'undefined') {
      this.amplitude.emit(AppAmplitudeEvent.ON_MAP_PAGE)
    }

    this.setMapHeight()
  }
})
export class MapView extends Vue {
  @Ref('mapRef')
  public readonly map?: HTMLDivElement

  protected readonly eventsRepository =
    this.$container.get<IEventsRepository>(EventsRepositoryType)

  protected readonly amplitude =
    this.$container.get<IAmplitude>(AmplitudeType)

  public events: EventModel[] = []
  public markers: (Marker<EventModel> | Marker<EventModel[]>)[] = []
  public minHeight: string | null = null
  public selected: Marker<EventModel> | null = null

  public isLoading = false
  public isReady: boolean = false

  protected groupedMarkers: Record<string, Set<EventModel>> = {}
  protected initialSelectedEvent: string | null = null

  public get hasEventInQuery (): boolean {
    return !!this.$route.query.event
  }

  public handleOnCategorySelected (categories: string[]): void {
    this.fetchEvents(categories.length ? { categories } : {})
  }

  protected buildGroupedMarkers (): void {
    const events = [...this.events]

    this.groupedMarkers = {}

    events.map((event: EventModel) => {
      const [lat, _] = event.extractGeo()

      if (!this.groupedMarkers[lat]) {
        this.groupedMarkers[lat] = new Set()
      }

      this.groupedMarkers[lat].add(event)
    })
  }

  protected buildMarkers (): void {
    const groupedEvents = Object.keys(this.groupedMarkers).map((lat) => {
      return {
        [lat]: Array.from(this.groupedMarkers[lat])
      }
    })

    this.markers = groupedEvents.map((groupedEvent: { [p: string]: EventModel[] }) => {
      const event = Object.values(groupedEvent)[0][0]
      const [lat, lon] = event.extractGeo()

      const colors = [
        'linear-gradient(90deg, #8A2387 0%, #E94057 50%, #F27121 100%)',
        'linear-gradient(90deg, #FC00FF 0%, #00DBDE 100%)',
        'linear-gradient(90deg, #C33764 0%, #1D2671 100%)',
        'linear-gradient(90deg, #2196F3 0%, #F44336 100%);'
      ]

      // For more than 1 event in one place.
      if (Object.values(groupedEvent)[0].length > 1) {
        const randomIndex = Math.floor(Math.random() * colors.length)
        return new Marker(lat, lon,
          `<div style="
                                background: ${colors[randomIndex]};
                                color: #fff;
                                font-weight: 600;
                                box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.10);">
                       +${Object.values(groupedEvent)[0].length}
                    </div>`,
          Object.values(groupedEvent)[0],
          (e: any) => {
            this.handleMarkerClick(e)
          }
        )
      }

      return new Marker(lat, lon,
        `<div
                        class="${event.category().key}"
                        style="
                        background-color: ${mapColorsRegistry[event.category().key].color};
                        box-shadow: ${mapColorsRegistry[event.category().key].border};">
                            ${mapIconsRegistry[event.category().key]}
                    </div>`,
        event,
        (e: any) => {
          this.handleMarkerClick(e)
        }
      )
    })
  }

  protected async fetchEvents (query: AnyObject = {}): Promise<void> {
    try {
      this.isLoading = true
      this.isReady = false
      const events = await this.eventsRepository.fetchEvents(1, 1000, { ...query, not_ended: true })
      this.events = events.items

      this.buildGroupedMarkers()
      this.buildMarkers()

    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false
      setTimeout(() => {
        this.isReady = true
      }, 300)
    }
  }

  protected handleMarkerClick (e: any): void {
    const { lat, lng } = e.latlng

    this.amplitude.emit(AppAmplitudeEvent.ON_EVENT_SELECT_ON_MAP)

    //@ts-ignore
    this.selected = [...this.markers].find((marker) => {
      const [_, _lat, _lon] = marker.toArray()

      // If marker's lat and lng is similar with selected marker return.
      if (_lat === lat && _lon === lng) {
        return marker
      }
    }) ?? null
  }

  @Watch('selected')
  protected onSelectedUpdate (value: Marker | null): void {
    this.buildMarkers()
    if (this.hasEventInQuery) {
      this.$router.push({
        name: 'events.map',
        query: { ...this.$route.query ?? {}, event: '' }
      })
    }
  }

  @Watch('markers')
  protected onEventsUpdate (): void {
    if (this.markers.length > 0 && this.hasEventInQuery && this.initialSelectedEvent) {
      //@ts-ignore
      this.selected = this.markers.filter((markerData) => {
        const eventData = markerData.getData()
        this.initialSelectedEvent = null

        if (Array.isArray(eventData)) {
          return eventData.find((ev) => {
              return ev.get('id').toString() === this.$route.query.event
            }
          )
        }

        //@ts-ignore
        if (eventData.get('id').toString() === this.$route.query.event) {
          return markerData
        }
      })[0] ?? null
    }
  }

  protected setMapHeight (): void {
    this.minHeight = `${window.innerHeight - (this.map?.getBoundingClientRect().y ?? 0)}px`
  }
}

export default MapView
