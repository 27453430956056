
import { Component, Prop, Vue } from 'vue-property-decorator'
import { AmplitudeType, AppAmplitudeEvent, IAmplitude } from '@/shared/services/amplitude'
import { EventModel } from '../../../models'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'MapSelectedEvent' })
export class MapSelectedEvent extends Vue {
  @Prop({ type: Object, required: true })
  public readonly event!: EventModel

  protected readonly amplitude =
    this.$container.get<IAmplitude>(AmplitudeType)

  /**
   * Determines the thumbnail image of the selected event.
   */
  public get thumbnail (): string | undefined {
    return Array.isArray(this.event.get('images')) && this.event.get('images').length > 0 ?
      this.event.get('images')[0].file : undefined
  }

  public close (): void {
    this.$emit('close')
  }

  public async goToEvent (): Promise<void> {
    this.amplitude.emit(AppAmplitudeEvent.ON_OPEN_EVENT_FROM_MAP)

    await this.$router.push({
      name: 'events.event',
      params: { slug: this.event.get('slug') },
    })
  }

  public showInGoogleMaps (): void {
    this.amplitude.emit(AppAmplitudeEvent.ON_SHOW_ON_GOOGLE_MAPS)

    window.open(this.event.get('googleMapsLink'), '_blank')
  }
}
export default MapSelectedEvent
