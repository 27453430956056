
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Javlon Khalimjanov <khalimjanov2000@gmail.com>
 */
@Component({ name: 'EmptyBanner' })
export class EmptyBanner extends Vue {
  @Prop({ type: Boolean, required: false })
  public readonly isLoading?: boolean
}
export default EmptyBanner
