
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { AmplitudeType, AppAmplitudeEvent, IAmplitude } from '@/shared/services/amplitude'
import { EventModel } from '../../../models'

import { carouselConfig } from './MapSelectedEvents.config'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
@Component({ name: 'MapSelectedEvents' })
export class MapSelectedEvents extends Vue {
  @Prop({ type: Array, required: true })
  protected events!: Array<EventModel>

  @Inject({ from: 'isMobile', default: false })
  public isMobile!: () => boolean

  protected readonly amplitude =
    this.$container.get<IAmplitude>(AmplitudeType)

  public readonly carouselConfig = carouselConfig

  public close (): void {
    this.$emit('close')
  }

  public async goToEvent (slug: string): Promise<void> {
    this.amplitude.emit(AppAmplitudeEvent.ON_OPEN_EVENT_FROM_MAP)
    await this.$router.push({
      name: 'events.event',
      params: { slug }
    })
  }

  /**
   * Determines the thumbnail image of the selected event.
   */
  public thumbnail (event: EventModel): string | undefined {
    return Array.isArray(event.get('images')) && event.get('images').length > 0 ?
      event.get('images')[0].file : undefined
  }

  public showInGoogleMaps (event: EventModel): void {
    this.amplitude.emit(AppAmplitudeEvent.ON_SHOW_ON_GOOGLE_MAPS)
    window.open(event.get('googleMapsLink'), '_blank')
  }
}

export default MapSelectedEvents
